import CryptoJS from 'crypto-js'

export default function useCrypto() {
  const SECRET_KEY = 'mdxl'
  const encrypt = payload => {
    return CryptoJS.AES.encrypt(JSON.stringify(payload), SECRET_KEY).toString()
  }

  const decrypt = (payload) => {
    const bytes = CryptoJS.AES.decrypt(payload, SECRET_KEY)
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
  }

  return {
    encrypt,
    decrypt,
  }
}
