import apiClient from '@/services/axios'
import store from 'store'
import useCrypto from '@/composables/use-crypto'

const { encrypt } = useCrypto()

export async function login(username, password) {
  // jika login maka hapus dulu access_token nya
  store.remove('accessToken')
  store.remove('profile')
  store.remove('status')

  return apiClient
    .post('/api/login', {
      username,
      password,
    })
    .then(response => {
      if (response) {
        const { access_token, password_at, reset_status } = response.data
        if (access_token) {
          let status = {
            password_at: password_at,
            reset_status: reset_status,
          }
          store.set('status', status)
          store.set('accessToken', encrypt(access_token))
        }
        return response.data
      }
      return false
    })
    .catch(err => {
      console.log('jwt error login', err)
      return Promise.reject(err)
    })
}

export async function loginAuth(payload) {
  // jika login maka hapus dulu access_token nya
  store.remove('accessToken')
  store.remove('profile')
  store.remove('status')

  let providers = {
    microsoft: '/api/auth/ms365',
    google: '/api/auth/google',
  }

  return apiClient
    .post(providers[payload.provider], payload)
    .then(({ data }) => {
      if (data) {
        const { access_token, password_at, reset_status } = data
        if (access_token) {
          let status = {
            password_at: password_at,
            reset_status: reset_status,
          }
          store.set('status', status)
          store.set('accessToken', encrypt(access_token))
        }
        return data
      }
      return false
    })
    .catch(err => {
      console.log('jwt error login', err)
      return Promise.reject(err)
    })
}

export async function register(email, password, name) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { access_token } = response.data
        if (access_token) {
          store.set('accessToken', encrypt(access_token))
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount(path) {
  return apiClient
    .post('/api/refresh', { path: path })
    .then(response => {
      if (response) {
        const { access_token } = response.data
        if (access_token) {
          store.set('accessToken', encrypt(access_token))
        }
        return response.data
      }
      return false
    })
    .catch(err => {
      console.log(err)
      if (err.response.status === 401) {
        throw err
      }
      return false
    })
}

export async function userMenu() {
  return apiClient.get('/api/auth/menu').then(response => {
    if (response) {
      return response.data
    }
    return []
  })
}

export async function logout() {
  store.remove('accessToken')
  store.remove('status')
  return Promise.resolve()
}
