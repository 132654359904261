<template>
  <a-layout>
    <a-layout-content>
      <router-view v-slot="{ Component }">
        <transition :name="settings.routerAnimation" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </a-layout-content>
    <!-- <a-layout-footer style="background-color: #630700;">
      <vb-footer color="white" />
    </a-layout-footer> -->
  </a-layout>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import VbFooter from '@/components/Footer'

export default {
  name: 'MainLayout',
  components: {
    VbFooter,
  },
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)
    return { settings }
  },
}
</script>

<style lang="scss" module></style>
