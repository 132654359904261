import store from '@/store'

/**
 * Create comment node
 *
 * @private
 * @author https://stackoverflow.com/questions/43003976/a-custom-directive-similar-to-v-if-in-vuejs#43543814
 */
const commentNode = (el, vnode) => {
  const comment = document.createComment(' ')

  Object.defineProperty(comment, 'setAttribute', {
    value: () => undefined,
  })

  vnode.text = ' '
  vnode.elm = comment
  vnode.isComment = true
  vnode.context = undefined
  vnode.tag = undefined
  if (vnode.data) vnode.data.directives = undefined
  if (vnode.componentInstance) vnode.componentInstance.$el = comment
  if (el.parentNode) el.parentNode.replaceChild(comment, el)
}

const distributor = () => store.getters['user/distributor']

export default {
  mounted(el, binding, vnode) {
    const isDistributor = () => distributor().isDistributor

    if (binding.arg === 'hide' && binding.modifiers.distributor) {
      // hide if distributor
      if (isDistributor()) {
        // if user is has role distributor
        try {
          commentNode(el, vnode)
        } catch (error) {
          console.error('binding failed ', error)
        }
      }
    }

    // console.log({ binding })
  },
}
