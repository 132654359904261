import store from '@/store';
import localStorage from 'store'


const checkAccess = roles => {
    if (typeof roles == 'string') {
        roles = roles.split(',')
    }


    const getActions =  localStorage.get('profile').actions;

    let actions = getActions
    for (let i = 0; i < roles.length; i++) {
        for (let j = 0; j < actions.length; j++) {
            if (roles[i] == actions[j]) {
                return true
            }
        }
    }
    return false
}

const HasAccess = {
    beforeMount: (el, binding) => {
        if (!checkAccess(binding.value)) {
            el.style.display = 'none';
        }
    },
    updated: (el, binding) => {
        if (!checkAccess(binding.value)) {
            el.style.display = 'none';
        }
    },
}

export default HasAccess
