<template>
  <div>
    {{ $t('topBar.status') }}
    <span class="ml-2 p-1 badge bg-danger text-white font-size-12 text-uppercase">Trialing</span>
  </div>
</template>

<style lang="scss" module>
@import './style.module.scss';
</style>
