// menu ini sudah tidak di-pakai
// menu sudah diambil langsung dari database

// import vuex from '@/store'
// console.log(vuex.getters['user/menu'])
export const getMenuData = []

export const getMenuData1 = [
  // VB:REPLACE-START:MENU-CONFIG
  {
    title: 'Dashboard',
    key: '__dashboard',
    url: '/dashboard',
    icon: 'fe fe-home',
  },
  {
    title: 'Akun',
    key: '1111',
    url: '/akun',
    icon: 'fe fe-user',
  },
  {
    title: 'Parameter',
    key: '2222',
    url: '/parameter',
    icon: 'fe fe-folder-minus',
    children: [
      {
        title: 'Lokasi Area',
        key: '3333',
        url: '/parameter/lokasi-area',
      },
      {
        title: 'Metode Pembayaran',
        key: '4444',
        url: '/parameter/metode-pembayaran',
      },
      {
        title: 'Layanan Pelanggan',
        key: '5555',
        url: '/parameter/layanan-pelanggan',
      },
      {
        title: 'Tanggal Mundur Penjualan',
        key: '6666',
        url: '/parameter/tanggal-mundur-penjualan',
      },
      {
        title: 'Parameter Produk Satuan Penjualan',
        key: '7777',
        url: '/parameter/parameter-produk-satuan-penjualan',
      },
      {
        title: 'Parameter Produk Satuan Pembelian',
        key: '8888',
        url: '/parameter/parameter-produk-satuan-pembelian',
      },
      {
        title: 'Parameter Produk Kategori',
        key: '9999',
        url: '/parameter/parameter-produk-kategori',
      },
      {
        title: 'Parameter Produk Merk',
        key: '11110',
        url: '/parameter/parameter-produk-merk',
      },
      {
        title: 'FAQ',
        key: '12221',
        url: '/parameter/faq',
      },
      {
        title: 'Syarat dan Ketentuan',
        key: '13332',
        url: '/parameter/syarat-dan-ketentuan',
      },
      {
        title: 'Parameter Produk',
        key: '14443',
        url: '/parameter/parameter-produk',
      },
      {
        title: 'Pemetaan Regional Area',
        key: '15554',
        url: '/parameter/pemetaan-regional-area',
      },
      {
        title: 'Pemetaan Grup Area',
        key: '16665',
        url: '/parameter/pemetaan-grup-area',
      },
      {
        title: 'Pemetaan Organisasi',
        key: '17776',
        url: '/parameter/pemetaan-organisasi',
      },
      {
        title: 'Konversi UoM',
        key: '18887',
        url: '/parameter/konversi-uom',
      },
      {
        title: 'Pemetaan Wilayah',
        key: '19998',
        url: '/parameter/pemetaan-wilayah',
      },
      {
        title: 'Satuan',
        key: '21109',
        url: '/parameter/satuan',
      },
      {
        title: 'Konversi Satuan',
        key: '22220',
        url: '/parameter/konversi-satuan',
      },
      {
        title: 'Kontrak Volume',
        key: '23331',
        url: '/parameter/kontrak-volume',
      },
      {
        title: 'Parameter Produk Tipe',
        key: '24442',
        url: '/parameter/parameter-produk-tipe',
      },
      {
        title: 'Parameter Produk Sub Kategori',
        key: '25553',
        url: '/parameter/parameter-produk-sub-kategori',
      },
      {
        title: 'Parameter Produk Supplier',
        key: '26664',
        url: '/parameter/parameter-produk-supplier',
      },
    ],
  },
  {
    title: 'Manajemen User',
    key: '27775',
    url: '/manajemen-user',
    icon: 'fe fe-users',
    children: [
      {
        title: 'Daftar Pengguna',
        key: '28886',
        url: '/manajemen-user/daftar-pengguna',
      },
      {
        title: 'Kelola Pengguna',
        key: '29997',
        url: '/manajemen-user/kelola-pengguna',
      },
      {
        title: 'Peran Pengguna',
        key: '31108',
        url: '/manajemen-user/peran-pengguna',
      },
      {
        title: 'Sales Organisasi',
        key: '32219',
        url: '/manajemen-user/sales-organisasi',
      },
    ],
  },
  {
    title: 'Master',
    key: '33330',
    url: '/master',
    icon: 'fe fe-settings',
    children: [
      {
        title: 'Pengajuan Toko',
        key: '34441',
        url: '/master/pengajuan-toko',
      },
      {
        title: 'Kelola Toko',
        key: '35552',
        url: '/master/kelola-toko',
      },
      {
        title: 'Tambah Produk',
        key: '36663',
        url: '/master/tambah-produk',
      },
      {
        title: 'Kelola Produk',
        key: '37774',
        url: '/master/kelola-produk',
      },
      {
        title: 'Bank',
        key: '38885',
        url: '/master/bank',
      },
      {
        title: 'Segmen Pelanggan',
        key: '39996',
        url: '/master/segmen-pelanggan',
      },
      {
        title: 'Unggah Master Toko',
        key: '41107',
        url: '/master/unggah-master-toko',
      },
      {
        title: 'Kelola Distributor',
        key: '42218',
        url: '/master/kelola-distributor',
      },
      {
        title: 'Kelola Gudang',
        key: '43329',
        url: '/master/kelola-gudang',
      },
      {
        title: 'Master API Distributor',
        key: '44440',
        url: '/master/master-api-distributor',
      },
      {
        title: 'Tambah Packing Plant',
        key: '45551',
        url: '/master/tambah-packing-plant',
      },
      {
        title: 'Kelola Packing Plant',
        key: '46662',
        url: '/master/kelola-packing-plant',
      },
      {
        title: 'Pengajuan Gudang',
        key: '47773',
        url: '/master/pengajuan-gudang',
      },
      {
        title: 'Log Api',
        key: '488843',
        url: '/master/log-api',
      },
      {
        title: 'Master Data Jaringan LT',
        key: '49991',
        url: '/master/master-data-jaringan-lt',
      },
      {
        title: 'Master Data LT',
        key: '49992',
        url: '/master/master-data-lt',
      },
      {
        title: 'Mapping Distributor By Product',
        key: '49993',
        url: '/master/mapping-dist',
      },
      {
        title: 'Master Toko',
        key: '49994',
        url: '/master/master-toko-v2',
      },
      {
        title: 'Unggah Mapping Distributor',
        key: '49995',
        url: '/master/unggah-mapping-distributor',
      },
    ],
  },
  {
    title: 'Laporan',
    key: '49995',
    url: '/laporan',
    icon: 'fe fe-bar-chart',
    children: [
      {
        title: 'Kelola Laporan',
        key: '51106',
        url: '/laporan/kelola-laporan',
      },
      {
        title: 'Lihat Pemetaan Gudang',
        key: '52217',
        url: '/laporan/lihat-pemetaan-gudang',
      },
      {
        title: 'Laporan Penjualan',
        key: '53328',
        url: '/laporan/laporan-penjualan',
      },
      {
        title: 'Transaksi Distributor',
        key: '54439',
        url: '/laporan/transaksi-distributor',
      },
      {
        title: 'Transaksi Gudang',
        key: '55550',
        url: '/laporan/transaksi-gudang',
      },
      {
        title: 'Performance Brand',
        key: '56661',
        url: '/laporan/performance-brand',
      },
      {
        title: 'Laporan Transaksi Bulanan ID Toko',
        key: '57772',
        url: '/laporan/transaksi-toko',
      },
      {
        title: 'Konfigurasi Loyalti',
        key: '58883',
        url: '/laporan/konfigurasi-loyalti',
      },
      {
        title: 'GPM per Gudang',
        key: '59994',
        url: '/laporan/gpm-per-gudang',
      },
      {
        title: 'GPM per Distributor',
        key: '61105',
        url: '/laporan/gpm-per-distributor',
      },
      {
        title: 'GPM per Produk',
        key: '62216',
        url: '/laporan/gpm-per-produk',
      },
      {
        title: 'Potensi Penjualan Detail',
        key: '63327',
        url: '/laporan/level-stock',
      },
      {
        title: 'Laporan Loyalti Poin',
        key: '64438',
        url: '/laporan/laporan-loyalti-poin',
      },
      {
        title: 'Laporan Transaksi Toko Harian',
        key: '65549',
        url: '/laporan/laporan-transaksi-toko-harian',
      },
      {
        title: 'Laporan Kontrak Volume',
        key: '66660',
        url: '/laporan/laporan-kontrak-volume',
      },
      {
        title: 'Numerik Distribution',
        key: '67771',
        url: '/laporan/numerik-distribution',
      },
      {
        title: 'Unggah Program Up Front',
        key: '68882',
        url: '/laporan/unggah-program-up-front',
      },
      {
        title: 'Program Up Front',
        key: '69993',
        url: '/laporan/program-up-front',
      },
      {
        title: 'Transaksi Distributor 2',
        key: '71104',
        url: '/laporan/transaksi-distributor-2',
      },
      {
        title: 'Konfigurasi Up Front',
        key: '72215',
        url: '/laporan/konfigurasi-up-front',
      },
      {
        title: 'Laporan Up Front',
        key: '73326',
        url: '/laporan/laporan-up-front',
      },
      {
        title: 'List Toko Aktif',
        key: '74437',
        url: '/laporan/list-toko-aktif',
      },
      {
        title: 'Forcapos Delivery',
        key: '75548',
        url: '/laporan/forcapos-delivery',
      },
      {
        title: 'Potensi Penjualan Detail',
        key: '75549',
        url: '/laporan/level-stock',
      },
      {
        title: 'Potensi Penjualan Per Distrik',
        key: '75550',
        url: '/laporan/potensi-penjualan-per-distrik',
      },
      {
        title: 'Potensi Penjualan PerDistrik & Per Distributor',
        key: '75551',
        url: '/laporan/potensi-penjualan-distrik-distributor',
      },
      {
        title: 'Potensi Penjualan Per Distributor',
        key: '75552',
        url: '/laporan/potensi-penjualan-per-distributor',
      },
      {
        title: 'Potensi Penjualan Detail',
        key: '75553',
        url: '/laporan/potensi-penjualan-detail',
      },
      {
        title: 'GPM Perdistrik',
        key: '75554',
        url: '/laporan/gpm-per-distrik',
      },
      {
        title: 'Laporan Cluster Toko',
        key: '75555',
        url: '/laporan/laporan-cluster-toko',
      },
      {
        title: 'Laporan Detail Transaksi Toko by Product',
        key: '75556',
        url: '/laporan/detail-transaksi-toko-by-product',
      },
      {
        title: 'Laporan ID Transaksi Toko by Product Distributor',
        key: '75557',
        url: '/laporan/laporan-id-toko-by-product-distributor',
      },
      {
        title: 'Syncron SDFC',
        key: '75558',
        url: '/laporan/syncron-sdfc',
      },
    ],
  },
  {
    title: 'Audit Trail',
    key: '76659',
    url: '/audit-trail',
    icon: 'fe fe-check-circle',
  },
  {
    title: 'Gudang',
    key: '77770',
    url: '/gudang',
    icon: 'fe fe-truck',
  },
  {
    title: 'Override',
    key: '78881',
    url: '/override',
    icon: 'fe fe-fast-forward',
  },

  // VB:REPLACE-END:MENU-CONFIG
]
