export const routerPromoManagement = [
  {
    path: '/promo-management/article',
    meta: { title: 'Article' },
    component: () => import('./views/promo-management/article'),
  },
  {
    path: '/promo-management/list-news',
    meta: { title: 'List News' },
    component: () => import('./views/promo-management/list-news'),
  },
]
