import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import GuestLayout from '@/layouts/Guest'
import store from '@/store'
import { routerMenus } from './router-menu'
import { routerLaporan } from './router-laporan'
import {
  routerToko,
  routerATbusiness,
  routerMaster,
  routerATsalesman,
  routerHelpdesk,
  routerTimIO,
  routerPenjualan,
  routerDistributor,
  routerAksestoko,
} from './router-group'
import { routerMetabase } from './router-metabase'
import { routerManualBook } from './router-manual-book'
import { routerConfiguration } from './router-configuration'
import { routerRestApi } from './router-rest-api'
import { routerPromoManagement } from './router-promo-management'
import { routerProgramLoyalty } from './router-program-loyalty'
import {
  ROLE_KAM,
  ROLE_CORSALES,
  hasRoles,
} from '@/helpers'

const router = createRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      // VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT
      redirect: '/dashboard',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // VB:REPLACE-START:ROUTER-CONFIG
        {
          path: '/dashboard',
          meta: { title: 'Dashboard', isLogo: false },
          component: () => import('./views/dashboard'),
        },
        // VB:REPLACE-END:ROUTER-CONFIG
        ...routerMenus,
        ...routerLaporan,
        ...routerConfiguration,
        ...routerRestApi,
        ...routerMetabase,
        ...routerManualBook,
        ...routerPromoManagement,
        ...routerProgramLoyalty,
        ...routerToko,
        ...routerATbusiness,
        ...routerMaster,
        ...routerATsalesman,
        ...routerHelpdesk,
        ...routerTimIO,
        ...routerPenjualan,
        ...routerDistributor,
        ...routerAksestoko,
      ],
    },
    // guest
    // {
    //   path: '/guest',
    //   name: 'guest',
    //   redirect: '/guest/welcome',
    //   component: GuestLayout,
    //   meta: {
    //     authRequired: false,
    //     hidden: true,
    //   },
    //   children: [
    //     {
    //       path: 'welcome',
    //       meta: { title: 'Welcome' },
    //       component: () => import('./views/landing-page'),
    //     },
    //   ],
    // },

      {
      path: '/guest',
      name: 'guest',
      redirect: '/guest/welcome',
      component: GuestLayout,
      meta: {
        authRequired: false,
        hidden: true,
      },
      children: [
        {
          path: 'welcome',
          meta: { title: 'Welcome' },
          component: () => import('./views/welcome'),
        },
      ],
    },

    // {
    //   path: '/guest/welcome',
    //   name: 'guest',
    //   component: () => import('./views/landing-page'),
    //   meta: {
    //     authRequired: false,
    //     hidden: true,
    //     title: 'Welcome',
    //   },
    // },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          name: 'route404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          name: 'login',
          meta: {
            title: 'Sign In',
          },
          redirect: '/guest/welcome',
          // component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/auth/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },
    {
      path: '/faq',
      name: 'faq',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
        title: 'Faq',
      },
      children: [
        {
          path: '/faq',
          meta: { title: 'Faq' },
          component: () => import('./views/faq'),
        },
      ],
    },
    {
      path: '/layanan-pelanggan',
      name: 'layanan-pelanggan',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
        title: 'Layanan Pelanggan',
      },
      children: [
        {
          path: 'layanan-pelanggan',
          meta: { title: 'Layanan Pelanggan' },
          component: () => import('./views/layanan-pelanggan'),
        },
      ],
    },
    // Redirect to 404
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'route404' },
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  // NProgress.start()
  // setTimeout(() => {
  //   NProgress.done()
  // }, 300)

  if (to.matched.some(record => record.meta.authRequired)) {
    // console.log('beforeEach ', store.state.user)
    await store
      .dispatch('user/LOAD_CURRENT_ACCOUNT', to.path)
      .then(() => {
        // only dashboard by role
        if (to.fullPath == '/dashboard' && hasRoles([ROLE_KAM, ROLE_CORSALES])) {
          next({
            path: '/atbusiness/dashboard',
          })
        }

        next()
      })
      .catch(() => {
        console.log('routerjs:137, failed load user')
        next({
          path: '/guest/welcome',
        })
      })
  } else {
    // console.log('beforeEach else', store.state.user)
    next()
  }
})

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    console.log('error', error)
    window.location.reload()
  }
})

export default router
