import axios from 'axios'
import Form from 'vform'
import store from 'store'
import { notification } from 'ant-design-vue'
import vuex from '@/store'
import useCrypto from '@/composables/use-crypto'
const { decrypt } = useCrypto()

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://api.mdxl.xyz',
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken');
  if (accessToken != undefined) {
    let decryptToken = decrypt(accessToken)
    request.headers.Authorization = `Bearer ${(decryptToken)}`
    request.headers.AccessToken = (decryptToken)
  }
  return request
})

apiClient.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log(response)
    // const user = vuex.getters['user/user']
    // const isAm = user.roles.find(r => r.role === 'TSO')
    // if (isAm && response.config.url === '/api/auth/menu') {
    // response.data = am || response.data
    // }
    return response
  },
  error => {
    // Errors handling
    const { response } = error
    if (!response) return

    const { data } = response
    if (data) {
      console.log('Error from axios ', data)
      // notification.warning({
      //   message: data.message,
      // })

      return Promise.reject(error)
    }
  },
)

export const apiAksestoko = axios.create({
  baseURL: process.env.VUE_APP_AKSESTOKO_BASE_URL || 'https://web-qa.aksestoko.id',
})

apiAksestoko.interceptors.request.use(request => {
  const accessToken =
    process.env.VUE_APP_AKSESTOKO_TOKEN ||
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5ZTYwNGQ5OC0xMDZkLTQ2ZTItYWRkNi1lNzI0NDcwZTVjN2IiLCJqdGkiOiI0ODg1Yjc5ZWUzNjY2YjI0Njg4NTUyNDQ1YzM5YTZkODg5YWViODA3ZDRhZDk0YmZlNDRmZTcwMjhjYjI4YTViMDA4MWVhMGI4ZWVjODdhYiIsImlhdCI6MTY2MTEzNTE5MywibmJmIjoxNjYxMTM1MTkzLCJleHAiOjE2OTI2NzExOTMsInN1YiI6IiIsInNjb3BlcyI6WyJyZWFkOnNldHRpbmciLCJ3cml0ZTpzZXR0aW5nIl19.Tf9LpJ9BsKJsCNzaGMa6icYIYAFCgCbYZDmNOGLnodPENgyDhdZ9j-HQ_B9_kO3Mees5UFXPnfKQhnWhuMLdyHv52-r4Ob0RhNV2j9LVTKUAx27_oXqIICURAWeqfmMUZmKNxRvH2ggZiva8b7mdIXNRfjgIWreW8QMvk4qQflh2uX8jZ8mMzGDQf3GJN9hsqiniwE5pKKKdCybJpo-UZpFoDNGp26zkttHw8vPjsRDFS0hemp7b3SPhqu5W9k1Q2nC4tkA5-CStYb1xU18BSdyE1BzP2sqEoBd7FUSECL67glaUyNCElXbTB0sd6hEzIcE67-GzRJsoOexfg-K-6g'
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  return request
})

apiAksestoko.interceptors.response.use(
  function(response) {
    return response
  },
  error => {
    // Errors handling
    const { response } = error
    if (!response) return

    const { data } = response
    if (data) {
      console.log('Error from axios ', data)
      // notification.warning({
      //   message: data.message,
      // })

      return Promise.reject(error)
    }
  },
)

// custom axios instance - https://vform.vercel.app/
Form.axios = apiClient

export default apiClient
