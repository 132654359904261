<template>
  <a-table
    v-bind="$attrs"
    bordered
    size="small"
    :pagination="overridePagination != null ? overridePagination : pagination"
    style="white-space: pre-wrap; overflow-x: auto;"
  >
    <template v-for="(_, slot) of $slots" #[slot]="scope" :key="slot">
      <slot :name="slot" v-bind="scope" />
    </template>
    <template v-if="$props.meta !== undefined && $props.meta !== null" #no="{ index }">
      {{ startRow + index }}
    </template>
  </a-table>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'MdTable',
  inheritAttrs: false,
  props: {
    meta: {
      type: Object,
      default: null,
    },
    pagination: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props) {
    const startRow = computed(() => {
      if (props.meta === null || props.meta === undefined) return null
      if (props.meta.currentPage === null || props.meta.currentPage === undefined) return null
      if (props.meta.perPage === null || props.meta.perPage === undefined) return null

      return (props.meta.currentPage - 1) * props.meta.perPage + 1
    })

    const overridePagination = computed(() => {
      if (props.meta === null || props.meta === undefined) return null
      if (props.meta.currentPage === null || props.meta.currentPage === undefined) return null
      if (props.meta.perPage === null || props.meta.perPage === undefined) return null

      return {
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
        showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
        total: props.meta.totalCount,
        pageSize: props.meta.perPage,
        current: props.meta.currentPage,
      }
    })

    return { startRow, overridePagination }
  },
}
</script>

<style></style>
