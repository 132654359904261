<template>
  <div class="logo">
    <div class="d-flex flex-wrap align-items-center">
      <div
        style="background-color: transparent"
        class="d-flex flex-wrap align-items-center vb__utils__avatar vb__utils__avatar--size64 flex-shrink-0 mr-4"
      >
        <img src="resources/images/gb-sisi/sig-logo.png" alt="SIG Logo" />
      </div>
      <div class="mr-auto">
        <div class="text-dark font-weight-bold font-size-24">
          <span class="mr-3">PT. Semen Indonesia Group</span>
        </div>
        <!-- <div>
          semen.indonesia@gmail.com
        </div> -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.logo {
  padding-left: 2rem;
  padding-top: 0.4rem;
  background-color: transparent;
}
</style>
