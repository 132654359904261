<template>
  <a-layout class="vb__layout">
    <a-layout-content>
      <router-view v-slot="{ Component }">
        <transition :name="settings.routerAnimation" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AuthLayout',
  components: {},
  computed: mapState(['settings']),
}
</script>
