<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown" class="d-flex">
      <div>
        <a-avatar shape="circle" size="medium" :class="$style.avatar">
          <template #icon><UserOutlined /></template>
        </a-avatar>
      </div>
      <div class="d-none d-sm-block">{{ profile.name || 'Anonymous' }}</div>
    </div>
    <template #overlay>
      <a-menu>
        <a-menu-item>
          <div>
            <strong>{{ profile.name || 'Anonymous' }}</strong>
          </div>
        </a-menu-item>
        <a-menu-divider />
        <!-- <a-menu-item>
          <div>
            <strong class="mr-1"><HomeOutlined /></strong> -
          </div>
        </a-menu-item>
        <a-menu-divider /> -->
        <a-menu-item>
          <div>
            <strong class="mr-1"><PhoneOutlined /></strong>{{ !profile.phone ? '-' : profile.phone }}
          </div>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item>
          <div>
            <strong class="mr-1"><MailOutlined /></strong>{{ profile.email }}
          </div>
        </a-menu-item>
        <a-menu-divider />
        <!-- user masking -->
        <template v-if="state.status_masking">
          <a-menu-item>
            <a @click="btnBackMaskingUser">
              <RollbackOutlined />
              Kembali
            </a>
          </a-menu-item>
        </template>
        <!-- normal user masking -->
        <template v-else>
          <template v-if="hasRoles([ROLE_ADMIN_BK])">
            <a-menu-item>
              <a @click="btnMaskingUser">
                <TeamOutlined />
                Masking User
              </a>
            </a-menu-item>
            <a-menu-divider />
          </template>
          <a-menu-item>
            <a @click="btnClearCache">
              <ClearOutlined />
              Clear Cache
            </a>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item>
            <a href="javascript: void(0);" @click="logout">
              <PoweroffOutlined />
              Keluar
            </a>
          </a-menu-item>
        </template>
      </a-menu>
    </template>
  </a-dropdown>

  <!-- viewer user -->
  <MaskingUser
      v-if="visibleMaskingUserModal"
      v-model:visible="visibleMaskingUserModal"
      v-model:status_masking="state.status_masking"
      @success="fetchDataList"/>
</template>

<script>
import {
  UserOutlined,
  // HomeOutlined,
  PhoneOutlined,
  MailOutlined,
  PoweroffOutlined,
  ClearOutlined,
  RollbackOutlined,
} from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { computed, createVNode, ref, reactive, watch, onMounted } from 'vue'
import { Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined, TeamOutlined } from '@ant-design/icons-vue'
import MaskingUser from './MaskingUser'
import store from 'store'
import {
    hasRoles,
    ROLE_ADMIN_BK,
} from '@/helpers'

//import socket from '@/services/socket-io'

export default {
  components: {
    UserOutlined,
    PhoneOutlined,
    MailOutlined,
    PoweroffOutlined,
    TeamOutlined,
    MaskingUser,
    ClearOutlined,
    RollbackOutlined,
  },
  setup() {
    const stores = useStore()
    const profile = computed(() => store.get('profile'))
    const state = reactive({
      status_masking: false,
    })

    const logout = () => {
      Modal.confirm({
        title: 'Konfirmasi',
        icon: createVNode(ExclamationCircleOutlined),
        content: 'Apakah Anda Yakin Ingin Keluar?',
        okText: 'Ya',
        cancelText: 'Tidak',
        onOk() {
          stores.dispatch('user/LOGOUT')
        },
      })
    }

    // handle viewer user
    const visibleMaskingUserModal = ref(false);
    const btnMaskingUser = () => {
        visibleMaskingUserModal.value = true
    }
    
    const btnClearCache = () => {
      Modal.confirm({
        title: 'Konfirmasi Clear Cache',
        icon: createVNode(ExclamationCircleOutlined),
        content: 'Melakukan clear cache dapat mengeluarkan Anda dari aplikasi, apakah Anda yakin?',
        okText: 'Ya',
        cancelText: 'Tidak',
        onOk() {
          localStorage.clear()
          stores.dispatch('user/LOGOUT')
        },
      })
    }
    
    const btnBackMaskingUser = () => {
      Modal.confirm({
        title: 'Konfirmasi Masking User',
        icon: createVNode(ExclamationCircleOutlined),
        content: 'Apakah Anda yakin ingin keluar dari mode masking user?',
        okText: 'Ya',
        cancelText: 'Tidak',
        onOk() {
          state.status_masking = false
        },
      })
    }

    onMounted(() => {
      state.status_masking = localStorage.getItem('status_masking')
    })

    watch(() => state.status_masking, (bool) => {
      if (bool) {
        localStorage.setItem('status_masking', bool)
      } else {
        const profileOriginal = store.get('profile_original')
        if (profileOriginal) {
          stores.dispatch('user/MASKING_USER', profileOriginal)
        }

        localStorage.removeItem('status_masking')
        localStorage.removeItem('profile_original')
      }
    })

    return {
      state,
      profile,
      logout,
      btnMaskingUser,
      visibleMaskingUserModal,
      btnClearCache,
      btnBackMaskingUser,
      hasRoles,
      ROLE_ADMIN_BK,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
