<template>
  <div :class="$style.topbar">
    <div class="mr-4">
      <vb-fav-pages />
    </div>
    <div class="mr-3 ml-auto">
      <!-- <vb-actions /> -->
    </div>
    <div class="">
      <vb-user-menu />
    </div>
  </div>
</template>

<script>
import VbUserMenu from './UserMenu'
import VbFavPages from './FavPages'
// import VbActions from './Actions'

export default {
  components: {
    VbFavPages,
    VbUserMenu,
    // VbActions,
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
