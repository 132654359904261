<template>
  <a-layout-sider
    :width="settings.leftMenuWidth"
    :collapsible="settings.isMobileView ? false : true"
    :collapsed="settings.isMenuCollapsed && !settings.isMobileView"
    :class="{
      [$style.menu]: true,
      [$style.white]: settings.menuColor === 'white',
      [$style.gray]: settings.menuColor === 'gray',
      [$style.dark]: settings.menuColor === 'dark',
      [$style.unfixed]: settings.isMenuUnfixed,
      [$style.shadow]: settings.isMenuShadow,
    }"
    @collapse="onCollapse"
  >
    <div
      :class="$style.menuOuter"
      :style="{
        width:
          settings.isMenuCollapsed && !settings.isMobileView
            ? '80px'
            : settings.leftMenuWidth + 'px',
        height:
          settings.isMobileView || settings.isMenuUnfixed
            ? 'calc(100% - 64px)'
            : 'calc(100% - 110px)',
      }"
    >
      <div :class="$style.logoContainer">
        <div :class="$style.logo">
          <img src="resources/images/gb-sisi/logo-md-merah.png" />
        </div>
      </div>
      <perfect-scrollbar :style="{ height: '100%' }">
        <a-skeleton
          active
          :loading="menuData.length === 1 && menuData[0].url === ''"
          :paragraph="{ rows: 10 }"
        >
          <a-menu
            v-model:open-keys="openKeys"
            :inline-collapsed="settings.isMobileView ? false : settings.isMenuCollapsed"
            :mode="'inline'"
            :selected-keys="selectedKeys"
            :inline-indent="15"
            :class="$style.navigation"
            @click="handleClick"
            @openChange="handleOpenChange"
          >
            <template v-for="(item, index) in menuData">
              <template v-if="!item.roles || item.roles.includes(user.role)">
                <a-menu-item-group v-if="item.category" :key="index">
                  <template #title>
                    {{ item.title }}
                  </template>
                </a-menu-item-group>
                <item
                  v-if="!item.children && !item.category"
                  :key="item.key"
                  :menu-info="item"
                  :styles="$style"
                />
                <sub-menu v-if="item.children" :key="item.key" :menu-info="item" :styles="$style" />
              </template>
            </template>
          </a-menu>
        </a-skeleton>
      </perfect-scrollbar>
    </div>
    <svg
      id="svgfilters"
      aria-hidden="true"
      style="position: absolute; width: 0; height: 0; overflow: hidden;"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <filter
          id="x-rays"
          filterUnits="objectBoundingBox"
          primitiveUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feColorMatrix in="SourceGraphic" type="matrix" :values="color" />
        </filter>
      </defs>
    </svg>
  </a-layout-sider>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { default as localStore } from 'store'
import find from 'lodash/find'
import { getMenuData } from '@/services/menu'
import SubMenu from './partials/submenu'
import Item from './partials/item'

export default {
  name: 'MenuLeft',
  components: { SubMenu, Item },
  setup() {
    const store = useStore()
    const route = useRoute()
    const menuData = computed(() => store.getters['user/menu'])
    const selectedKeys = ref([])
    const openKeys = ref([])
    const settings = computed(() => store.getters.settings)
    const isMenuCollapsed = computed(() => store.getters.settings.isMenuCollapsed)
    const user = computed(() => store.getters['user/user'])
    const pathname = computed(() => route.path)

    const onCollapse = (collapsed, type) => {
      const value = !settings.value.isMenuCollapsed
      store.commit('CHANGE_SETTING', { setting: 'isMenuCollapsed', value })
    }

    const handleClick = e => {
      if (e.key === 'settings') {
        store.commit('CHANGE_SETTING', {
          setting: 'isSettingsOpen',
          value: true,
        })
        return
      }
      localStore.set('app.menu.selectedKeys', [e.key])
      selectedKeys.value = [e.key]
    }

    const handleOpenChange = openKeys => {
      localStore.set('app.menu.openedKeys', openKeys)
      openKeys.value = openKeys
    }

    const setSelectedKeys = () => {
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item)
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key))
          }
          return flattenedItems
        }, [])
      const selectedItem = find(flattenItems(menuData.value.concat(), 'children'), [
        'url',
        pathname.value,
      ])
      selectedKeys.value = selectedItem ? [selectedItem.key] : []
    }

    onMounted(() => {
      openKeys.value = localStore.get('app.menu.openedKeys') || []
      selectedKeys.value = localStore.get('app.menu.selectedKeys') || []
      setSelectedKeys()
    })

    watch(pathname, () => {
      setSelectedKeys()
    })
    watch(isMenuCollapsed, () => (openKeys.value = []))

    const color = ref('')
    // logo warna biru
    const r = 0.29
    const g = 0.49
    const b = 0.95
    const a = 1
    color.value = `${r} 0 0 0 0
                  0 ${g} 0 0 0
                  0 0 ${b} 0 0
                  0 0 0 ${a} 0`

    return {
      menuData,
      selectedKeys,
      openKeys,
      settings,
      user,
      onCollapse,
      handleClick,
      handleOpenChange,
      color,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
.logo img {
  max-height: 64px;
  object-fit: scale-down;
  width: 80%;
}
.logoContainer {
  height: 64px;
  min-height: 64px;
  padding: 0;
  // filter: url('#x-rays'); // disable filter
}
</style>
