import HasAccess from './has-access'
import Can from './can.js'
import localStorage from 'store'

const registerDirective = app => {
  app.directive('has-access', HasAccess)
  app.directive('can', Can)

  app.directive('has-role', (el, {value}) => {
    const roles =  localStorage.get('profile').roles;

    if (!localStorage.get('profile') || (value.length > 0 && roles && !roles.some(e => value.indexOf(e.role) != -1))) {
        return el.remove()
    }
  })
}

export default registerDirective
