export const routerConfiguration = [{
        path: '/konfigurasi-system',
        meta: { title: 'Konfigurasi System' },
        component: () =>
            import ('./views/konfigurasi-system'),
    },
    {
        path: '/konfigurasi-system/konfigurasi-embedded-metabase',
        meta: { title: 'Konfigurasi Embedded Metabase' },
        component: () =>
            import ('./views/konfigurasi-system/konfigurasi-embedded-metabase'),
    },
    {
        path: '/konfigurasi-system/konfigurasi-menu',
        component: () =>
            import ('./views/konfigurasi-system/konfigurasi-menu'),
    },
    {
        path: '/konfigurasi-system/konfigurasi-manual-book',
        meta: { title: 'Konfigurasi Manual Book' },
        component: () =>
            import ('./views/konfigurasi-system/konfigurasi-manual-book'),
    },
    {
        path: '/konfigurasi-system/konfigurasi-synchron',
        meta: { title: 'Konfigurasi Synchron' },
        component: () =>
            import ('./views/konfigurasi-system/konfigurasi-synchron'),
    },
    {
        path: '/konfigurasi-system/version-rilis',
        meta: { title: 'Version Rilis' },
        component: () =>
            import ('./views/konfigurasi-system/version-rilis'),
    },
]