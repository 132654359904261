<template>
    <a-modal
        :visible="visible"
        title="Masking User"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="50%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @keydown="form.onKeydown($event)"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- user -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Masking User"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="user_id"
                        has-feedback>
                        <FilterUsers
                            style="width: 100%"
                            :mode="null"
                            :nopeg_status="0"
                            v-model:value="form.user_id"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row class="form-row" type="flex" justify="end">
                <a-col>
                    <a-form-item>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">Review</a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </AForm>

        <div v-if="form.errors.any()">
            <ASpace>
                <AAlert
                    v-for="(item, index) in form.errors.all()"
                    type="error"
                    :key="index"
                    :message="item.message"
                    banner
                    closable
                    @close="form.clear()" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    h,
} from 'vue'
import Form from 'vform'
import { Modal, message } from 'ant-design-vue'
import DeviceDetector from "device-detector-js"
import FilterUsers from '@/components/filter/FilterUsers'
import moment from 'moment'
import useCrypto from '@/composables/use-crypto'
import { useStore } from 'vuex'
import store from 'store'
import { toInteger } from 'lodash'

export default defineComponent({
    components: {
        FilterUsers,
    },
    props: {
        visible: [Boolean],
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const deviceDetector = new DeviceDetector()
        const device = deviceDetector.parse(navigator.userAgent)
        const stores = useStore()
        const { decrypt } = useCrypto()

        const form = reactive(new Form({
            user_id: [],
            ip_address: null,
            device: `${device.os.name}, ${device.device.brand}`,
            location: null,
            path: null,
        }))

        const formRef = ref();
        const state = reactive({
            loading: false,
            endpoint: '/api/mask-login',
            rules: {
                user_id: [
                    {
                        required: true,
                        message: "Masking user tidak boleh kosong!",
                    },
                ],
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleOk = async () => {
            state.loading = true

            form.post(state.endpoint)
                .then(({ data }) => {
                    Modal.info({
                        title: 'Anda login sebagai:',
                        content: h('div', {}, [
                            h('strong', 'Nama: '),
                            h('span', data.name),
                            h('br'),
                            h('strong', 'Role: '),
                            h('span', data.roles.map(item => item.role).join(',')),
                            h('br'),
                            h('strong', 'Group Role: '),
                            h('span', data.roles.map(item => item.group_role).join(',')),
                        ]),
                        maskClosable: true,
                        onOk() {
                            emit('update:status_masking', true)

                            store.set('profile_original', {
                                access_token: decrypt(store.get('accessToken')),
                                ...store.get('profile'),
                            })

                            stores.dispatch('user/MASKING_USER', data)
                            
                            handleModalCancel()
                        },
                    })
                })
                .finally(() => {
                    state.loading = false
                })
        }

        // handle func vue
        onMounted(() => {
            form.reset()
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
