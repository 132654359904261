/**
 * menu ATbusiness
 */
const routerATbusiness = [
  {
    path: '/atbusiness/dashboard',
    meta: { title: 'Dashboard' },
    component: () => import('./views/atbusiness/dashboard.vue'),
  },
  {
    path: '/atbusiness/customer-curah',
    meta: { title: 'Master Data Customer Curah' },
    component: () => import('./views/atbusiness/customer-curah'),
  },
  {
    path: '/atbusiness/group-customer',
    meta: { title: 'Master Data Grup Pelanggan' },
    component: () => import('./views/atbusiness/group-customer'),
  },
  {
    path: '/atbusiness/unggah-master-customer-curah',
    meta: { title: 'Unggah Master Customer Curah' },
    component: () => import ('./views/atbusiness/unggah-master-customer-curah'),
  },
  {
    path: '/atbusiness/rkap',
    meta: { title: 'Master Data RKAP' },
    component: () => import('./views/atbusiness/rkap'),
  },
  {
    path: '/atbusiness/penjualan-distributor-curah',
    meta: { title: 'Laporan Penjualan Distributor Curah' },
    component: () => import('./views/atbusiness/penjualan-distributor-curah'),
  },
  {
    path: '/atbusiness/sow-distributor-curah',
    meta: { title: 'Laporan SOW Distributor Curah' },
    component: () => import('./views/atbusiness/sow-distributor-curah'),
  },
  {
    path: '/atbusiness/pembayaran-customer-curah',
    meta: { title: 'Laporan Pembayaran Customer Curah' },
    component: () => import('./views/atbusiness/pembayaran-customer-curah.vue'),
  },
  {
    path: '/atbusiness/log-api',
    meta: { title: 'Log API' },
    component: () => import('./views/atbusiness/log-api'),
  },
  {
    path: '/atbusiness/distributor-curah',
    meta: { title: 'Master Data Distributor Curah' },
    component: () => import('./views/atbusiness/distributor-curah'),
  },
  {
    path: '/atbusiness/gpm-distributor',
    meta: { title: 'Laporan GPM Per Distributor' },
    component: () => import('./views/atbusiness/gpm-distributor.vue'),
  },
  {
    path: '/atbusiness/gpm-kelompok-pelanggan',
    meta: { title: 'Laporan GPM Per Kelompok Pelanggan' },
    component: () => import('./views/atbusiness/gpm-kelompok-pelanggan.vue'),
  },
  {
    path: '/atbusiness/ship-customer',
    meta: { title: 'Laporan Ship to Customer' },
    component: () => import('./views/atbusiness/ship-customer.vue'),
  },
  {
    path: '/atbusiness/master-api-distributor-curah',
    meta: { title: 'Master API Distributor Curah' },
    component: () => import('./views/atbusiness/master-api-distributor-curah'),
  },
  {
    path: '/atbusiness/reports/po-customer',
    meta: { title: 'Laporan PO Pelanggan' },
    component: () => import('./views/atbusiness/reports/po-customer.vue'),
  },
  {
    path: '/atbusiness/reports/finance',
    meta: { title: 'Laporan Keuangan' },
    component: () => import('./views/atbusiness/reports/finance.vue'),
  },
  {
    path: '/atbusiness/user-kam',
    meta: { title: 'Master Data KAM' },
    component: () => import('./views/atbusiness/user-kam'),
  },
]

/**
 * menu toko
 */
const routerToko = [
  {
    path: '/laporan-toko/hapus-suggestion-toko-lelang',
    meta: { title: 'Laporan Delete Suggestion Toko Lelang' },
    component: () => import('./views/laporan-toko/delete-suggestion-toko-lelang'),
  },
  {
    path: '/toko/transaksi-bulanan-id-toko',
    meta: { title: 'Laporan Transaks Bulanan ID Toko V2' },
    component: () => import('./views/laporan-toko/transaksi-bulanan-id-toko.vue'),
  },
  {
    path: '/toko/detail-transaksi-jaringan-lt',
    meta: { title: 'Laporan Detail Transaksi Jaringan LT' },
    component: () => import('./views/laporan-toko/detail-transaksi-jaringan-lt'),
  },
]

/**
 * menu master
 */
const routerMaster = [
  {
    path: '/master/data-mapping-toko',
    meta: { title: 'Data Mapping Toko (V2)' },
    component: () => import('./views/master/data-mapping-toko'),
  },
  {
    path: '/master/target-penjualan-distributor',
    meta: { title: 'Master Data Target Penjualan Distributor' },
    component: () => import('./views/master/target-penjualan-distributor'),
  },
  {
    path: '/master/target-penjualan-distributor/:id/target-toko-spc',
    name: 'master.targetspc.detail',
    meta: { title: 'Master Data ・ Target Penjualan Distributor ・ Detail Target By SPC' },
    component: () => import('./views/master/target-penjualan-distributor/DetailTargetSpcList.vue'),
  },
  {
    path: '/master/target-penjualan-distributor/:id/target-toko-distributor',
    name: 'master.targetdistributor.detail',
    meta: { title: 'Master Data ・ Target Penjualan Distributor ・ Detail Target Pertoko Distributor' },
    component: () => import('./views/master/target-penjualan-distributor/DetailTargetDistributorList.vue'),
  },
  {
    path: '/master/cluster',
    redirect: '/master/cluster/master',
    component: () => import('./views/master/cluster'),
    children: [
      {
        path: '/master/cluster/master',
        meta: { title: 'Master Cluster' },
        component: () => import('./views/master/cluster/master/index.vue'),
      },
      {
        path: '/master/cluster/configuration',
        meta: { title: 'Konfigurasi Cluster' },
        component: () => import('./views/master/cluster/configuration'),
      },
      {
        path: '/master/cluster/periode',
        meta: { title: 'Periode Cluster' },
        component: () => import('./views/master/cluster/periode'),
      },
    ],
  },
]

/**
 * menu ATsalesman
 */
const routerATsalesman = [
  {
    path: '/atsalesman/jadwal-kunjungan-salesman',
    meta: { title: 'Jadwal Kunjungan Salesman' },
    component: () => import('./views/atsalesman/jadwal-kunjungan-salesman'),
  },
  {
    path: '/atsalesman/master-user-salesman',
    meta: { title: 'Master User Salesman' },
    component: () => import('./views/atsalesman/master-user-salesman'),
  },
  {
    path: '/atsalesman/hasil-survey',
    meta: { title: 'Laporan Hasil Survey' },
    component: () => import('./views/atsalesman/hasil-survey'),
  },
  {
    path: '/atsalesman/hasil-survey/:id',
    meta: { title: 'ATSalesman ・ Laporan Hasil Survey ・ Detail Hasil Survey' },
    component: () => import('./views/atsalesman/hasil-survey/detail.vue'),
  },
  {
    path: '/atsalesman/master-target-salesman',
    meta: { title: 'Master Data Target Salesman' },
    component: () => import('./views/atsalesman/master-target-salesman'),
  },
  {
    path: '/atsalesman/performance-monitoring-board',
    meta: { title: 'Performance Monitoring Board' },
    component: () => import('./views/atsalesman/performance-monitoring-board'),
  },
  {
    path: '/atsalesman/dashboard-visit-performance-completeness-survey',
    meta: { title: 'Dashboard Visit Performance & Completeness Survey' },
    component: () => import('./views/atsalesman/dashboard-visit-performance-completeness-survey'),
  },
  {
    path: '/atsalesman/summary/survey',
    meta: { title: 'Summary Survey' },
    component: () => import('./views/atsalesman/summary/survey.vue'),
  },
  {
    path: '/atsalesman/movement/rbp',
    meta: { title: 'RBP Movement' },
    component: () => import('./views/atsalesman/movement/rbp.vue'),
  },
  {
    path: '/atsalesman/movement/rsp',
    meta: { title: 'RBP Movement' },
    component: () => import('./views/atsalesman/movement/rsp.vue'),
  },
  {
    path: '/atsalesman/summary/promotion',
    meta: { title: 'Summary Promo' },
    component: () => import('./views/atsalesman/summary/promotion.vue'),
  },
  {
    path: '/atsalesman/monitoring-price',
    meta: { title: 'Weekly Price Monitoring' },
    component: () => import('./views/atsalesman/monitoring-price.vue'),
  },
]

/**
 * menu Helpdesk
 */
const routerHelpdesk = [
  {
    path: '/helpdesk/data-sales-person',
    meta: { title: 'Data Sales Person' },
    component: () => import('./views/helpdesk/data-sales-person.vue'),
  },
  {
    path: '/helpdesk/mapping-wilayah-sales-person',
    meta: { title: 'Laporan Mapping Wilayah Sales Person' },
    component: () => import('./views/helpdesk/mapping-wilayah-sales-person.vue'),
  },
  {
    path: '/helpdesk/log-api',
    meta: { title: 'Log Api' },
    component: () => import('./views/helpdesk/log-api'),
  },
  {
    path: '/helpdesk/raw-data-distributor',
    meta: { title: 'Laporan Raw Data Distributor' },
    component: () => import('./views/helpdesk/raw-data-distributor'),
  },
  {
    path: '/helpdesk/forcapos-delivery',
    meta: { title: 'Forcapos Delivery' },
    component: () => import('./views/helpdesk/forcapos-delivery'),
  },
  {
    path: '/helpdesk/transaksi-stok-gudang-distributor',
    meta: { title: 'Laporan Transaksi Stok Gudang Distributor' },
    component: () => import('./views/helpdesk/transaksi-stok-gudang-distributor'),
  },
  {
    path: '/helpdesk/failed-sync-distributor',
    meta: { title: 'Failed Synchronize Distributor' },
    component: () => import('./views/helpdesk/failed-sync-distributor.vue'),
  },
  {
    path: '/helpdesk/master-api-distributor',
    meta: { title: 'Master Api Distributor' },
    component: () => import('./views/helpdesk/master-api-distributor'),
  },
]

/**
 * menu tim io
 */
const routerTimIO = [
  {
    path: '/tim-io/simulasi-level-stok',
    meta: { title: 'Simulasi Level Stok' },
    component: () => import('./views/laporan-tim-io/simulasi-level-stok'),
  },
  {
    path: '/tim-io/transaksi-e-pod',
    meta: { title: 'Laporan Transaksi E-Pod' },
    component: () => import('./views/laporan-tim-io/transaksi-epod'),
  },
]

/**
 * menu Penjualan
 */
const routerPenjualan = [
  {
    path: '/penjualan/toko-noo',
    meta: { title: 'Data Toko NOO V2' },
    component: () => import('./views/penjualan/toko-noo'),
  },
  {
    path: '/penjualan/toko-aktif-ca',
    meta: { title: 'Laporan Toko Aktif (CA) V2' },
    component: () => import('./views/penjualan/toko-aktif-ca'),
  },
]

/**
 * menu Distributor
 */
const routerDistributor = [
  {
    path: '/distributor/detail-transaksi',
    meta: { title: 'Laporan Detail Transaksi Distributor V2' },
    component: () => import('./views/distributor/detail-transaksi'),
  },
  {
    path: '/laporan/data-volume-penjualan-distributor',
    meta: { title: 'Data Volume Penjualan Distributor' },
    component: () => import('./views/distributor/data-volume-penjualan-distributor.vue'),
  },
]

/**
 * menu Aksestoko
 */
const routerAksestoko = [
  {
    path: '/aksestoko/registrasi-program-apresiasi-toko',
    meta: { title: 'Laporan Registrasi Program Apresiasi Toko 2022' },
    component: () => import('./views/laporan-aksestoko/registrasi-program-apresiasi-toko.vue'),
  },
]

export {
  routerToko,
  routerATbusiness,
  routerMaster,
  routerATsalesman,
  routerHelpdesk,
  routerTimIO,
  routerPenjualan,
  routerDistributor,
  routerAksestoko,
}